import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const OnlineCV = () => (
  <Layout>
    <SEO
      title="Why You Need an Online CV"
      description="Here's why you need an online CV as a data scientist. Stand out from the crowd and make sure potential employers see your work"
    />
    <h1>Why You Need an Online CV</h1>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/BNfXkcL.png"
        alt="Campbell data science why you need an online cv as a data scientist"
        description="why you need an online cv as a data scientist and programmer web development"
      />
    </div>
    <p>All of this makes for an employer’s dream because there is lots of choice but it is a nightmare for you as a the job applicant. The hard part then is standing out in this crowd of people to get to where you want to be.</p>
    <p>Luckily, there are a few thing you can do to stand out and making an online CV is one of the best. Especially if you are a web developer, data scientist or in any profession that includes computer programming and design.</p>

    <h2>Applying for a Job</h2>
    <p>
    Imagine a situation where an employer has posted a position for a job. It’s online so you can see 22 people have applied already. There are 6 days left to apply so you can bet a lot of people have seen the job and are fitting their CV to the job description.
    </p>
    <p>That is a good idea actually, sending a generic curriculum vitae will save time but can seriously harm your chances of getting the job.</p>
    <p>4 days left to go and 54 people have applied. The number of people you have to compete against is going up. You are confident in your abilities and honestly believe you would be a great fit for what was mentioned in the ad.</p>
    <h2>The Long Wait</h2>
    <p>You can be pretty sure that out of those 54 other applicant, there will be at least 3 people just as good as good and probably better in some areas.</p>
    <p>Normally, you would submit your CV along with a cover letter explaining why you are the best for the job without begging. Then you would sit back and wait for a response. A few things can happen, I’m sure you can imagine for yourself.</p>
    <p>The worst probably being; a long wait, no response, no feedback. Hopefully you haven’t been through this.</p>
    <p>A much better approach is to have an online CV and portfolio that you can forward to your potential employer. After all when it comes to the type of work we all do, a huge benefit is that it can all be displayed online. This is the great thing about programming and web development.</p>
    <h2>A Different Approach</h2>
    <p>Picture the same scenario above from the beginning. Imagine you have an online resume that took you a few weeks to build in your spare time 6 months ago. Have a look at our reasons <Link to="/data-scientist-learn-web-development/" style={{ textDecoration: "none" }}>
    why data scientists should learn web development
    </Link>.</p>
    <p>You included some of your favourite work and spent the time to get it exactly how you wanted it. Every so often you would do some new work that would be a good fit for your portfolio. Then you wrote a new description and include it on your site.</p>
    <p>Later on you plan out and complete a few personal passion projects and upload these too.</p>
    <p>When you see the job post you have been waiting for, you write a cover letter and make clear references to your online CV. You have to upload a pdf version of your CV and reference your online CV again. Now imagine what happens next.</p>
    <p>There's an email in your inbox saying only one other person submitted their online CV and yours was by far the best, they are surprised one person did this.</p>
    <h2>Standing Out From The Crowd</h2>
    <p>You don’t have the best qualifications but, the company really appreciates people who go the extra mile. They invite you in for an interview so you can take them through some of your projects and talk about the job position…</p>
    <p>As a person looking for a job, I would always recommend going above and beyond. Not just looking for a job but anywhere in life you want to be successful. Imagine what the competition is doing. Even better, if you can research and find out exactly what they are doing. Do that and more, much more and better in every way. This approach will give you a much better chance of success.</p>
    <p>What I mean by this is instead of simply finding job posts online and applying for them, go out and make positions. Find a business you would like to work for and make an effort to meet people who work there.</p>
    <p>Eventually finding people who are high enough up to either make hiring decisions or at least influence them. Then send them examples of your work and tell them why you would be a good fit.</p>
    <h2>Secret Recruitment</h2>
    <p>If you would prefer to not be so direct, another option is to build an online CV and have strong SEO. Making sure your audience knows where you are from and what you are looking for. Doing this right means you won’t need to apply for jobs, they will find you. Recruiters spend time combing through the web and social media like <a
      href="https://business.linkedin.com/talent-solutions/blog/linkedin-product-tips/2019/using-linkedin-recruiter-tips"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    LinkedIn for talent
    </a>. Make sure you come up in their searches.</p>
    <p>Did you know that the majority of job positions that are filled never see the job market. We call it the <a
      href="https://www.forbes.com/sites/nextavenue/2013/08/12/6-ways-to-crack-the-hidden-job-market/"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    hidden job market
    </a>.</p>
    <p>These positions are filled though word of mouth or recruiter finding talent through the web like we discussed. We’ve looked at why the job market can be a difficult place for job seekers. It can be a dream for recruiters because of the choice and statistics says there are bound to be suitable applicants in a big enough applicant pool. For large companies It can also a nightmare.</p>
    <h2>Artificial Intelligence in Recruitment</h2>
    <p>Consider 1000 applicants for a position. How do you deal with those numbers? Some businesses, scan the cover letter looking for red flags then discard and don’t ever get to the CV. Some companies have departments solely employed to deal with recruitment.</p>
    <p>The point is that as the number of applicants goes up, so does the amount of time and resources that goes into dealing with it.</p>
    <p>A modern approach and one that is gaining traction is <a
      href="https://www.hirevue.com/products/video-interviewing"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
    AI based video recruitment
    </a>. You are asked various questions and are required to submit your answers though a video system.</p>
    <p>Do you trust this type of system and look forward to it or completely hate it to the point where you might not even apply? The world is heading this way because it makes things easier for recruiters. They can view the results in their own time and spend much less time in the early interview stages.</p>
    <h2>Make Things Easier For Yourself</h2>
    <p>I don’t know about you but if I had the opportunity to avoid this kind of process, as the applicant, I would take it. Hopefully the advice I have given you helps and gives you a much better chance of getting that job you want.</p>
    <p>Also, don’t just limit this to finding employment. You can use this to reach out and find people in your community to work with. Try to make connections that can become friends and collaborate on future projects. You might even find that you really like building your own projects and find a way to employ yourself. The point is that it is important to know how to market yourself.</p>
    <p>Don’t just look for opportunities, create them.</p>

    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default OnlineCV
